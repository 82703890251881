/**
 * @prettier
 */

export function idPropertiesReducer(state = {}, action) {
    switch (action.type) {
        case '@@acuant/SET_ID_TYPE':
            return {
                ...state,
                cardType: action.data,
            };
        case '@@acuant/SET_ORIENTATION':
            return {
                ...state,
                orientation: action.data,
            };
        case '@@acuant/DECREMENT_SIDE': {
            let sidesLeft = state.sidesLeft;
            return {
                ...state,
                sidesLeft: sidesLeft - 1,
            };
        }
        case '@@acuant/INCREMENT_SIDE': {
            let sidesLeft = state.sidesLeft;
            return {
                ...state,
                sidesLeft: sidesLeft + 1,
            };
        }
        case '@@acuant/RESET_ID': {
            return {
                cardType: 0,
                orientation: 0,
                sidesLeft: 2,
            };
        }
        case 'SET_CROPPED_IMAGE': {
            return {
                ...state,
                croppedImage: action.data,
            };
        }
        case 'SET_DOWNSCALED_IMAGE': {
            return {
                ...state,
                downscaledImage: action.data,
            };
        }
        case 'SET_UNCROPPED_IMAGE': {
            return {
                ...state,
                uncroppedImage: action.data,
            };
        }
        default:
            return state;
    }
}
